import "./Home.css";
import Header from "../components/Header.js";
import Food from "../components/Food.js";
import Footer from "../components/Footer.js";
import PaleuImage from "../images/pelau.jpg";
import cornSoupImage from "../images/cornSoup.jpg";
import callalooImage from "../images/callaloo.jpg";
import curryImage from "../images/curry.webp";
import redBeansImage from "../images/redbeans.jpg";
import doublesImage from "../images/doubles.jpg";
import bakeAndFishImage from "../images/bakeAndFish.jpg";
import alooPieImage from "../images/alooPie.jpg";
import currentRollImage from "../images/currentRoll.jpeg";
import coconutRollImage from "../images/coconutRoll.jpg";
import greenSeasoningImage from "../images/GreenSeasoning.jpg";
import fruitCake from "../images/FruitCake.jpeg";
import pastel from "../images/Pastel.jpeg";
import ponchDeCreme from "../images/PonchDeCreme.jpeg";
import sorrel from "../images/Sorrel.jpeg";
import sweetbread from "../images/sweetbread.png";
import foodDescriptions from "../foodText.js";

function Home() {
  return (
    <div>
      <Header />
      <main className="food-grid">
        {" "}
        <Food
          foodImage={cornSoupImage}
          foodImageAlt="Photo of Corn Soup"
          foodName="Trini Corn Soup"
          foodOptions={foodDescriptions.cornSoupOptions}
          foodPricing={foodDescriptions.cornSoupPricing}
        />{" "}
        <Food
          foodImage={PaleuImage}
          foodImageAlt="Photo of Pelau"
          foodName="Pelau"
          foodOptions={foodDescriptions.pelauOptions}
          foodPricing={foodDescriptions.pelauPricing}
        />
        <Food
          foodImage={callalooImage}
          foodImageAlt="Photo of Callaloo"
          foodName="Callaloo, Rice, Stew Chicken"
          foodOptions={foodDescriptions.callalooOptions}
          foodPricing={foodDescriptions.callalooPricing}
        />
        <Food
          foodImage={redBeansImage}
          foodImageAlt="Photo of Red Beans"
          foodName="Beans, Rice, Stew Chicken, Green Salad"
          foodOptions={foodDescriptions.redBeansOptions}
          foodPricing={foodDescriptions.redBeansPricing}
        />
        <Food
          foodImage={curryImage}
          foodImageAlt="Photo of Curry"
          foodName="Curry Chicken, Channa, Potatos, Basmati Rice"
          foodOptions={foodDescriptions.curryOptions}
          foodPricing={foodDescriptions.curryPricing}
        />
        <Food
          foodImage={doublesImage}
          foodImageAlt="Photo of Doubles"
          foodName="Doubles"
          foodOptions={foodDescriptions.doublesOptions}
          foodPricing={foodDescriptions.doublesPricing}
        />
        <Food
          foodImage={bakeAndFishImage}
          foodImageAlt="Photo of Bake and Fish"
          foodName="Bake and Fish or Shrimp"
          foodOptions={foodDescriptions.bakeAndFishOptions}
          foodPricing={foodDescriptions.bakeAndFishPricing}
        />
        <Food
          foodImage={alooPieImage}
          foodImageAlt="Photo of Aloo Pie"
          foodName="Aloo (potato) Pie"
          foodOptions={foodDescriptions.alooPieOptions}
          foodPricing={foodDescriptions.alooPiePricing}
        />
        <Food
          foodImage={currentRollImage}
          foodImageAlt="Photo of Trinidad Current Roll"
          foodName="Trini Current Roll"
          foodOptions={foodDescriptions.currentRollOptions}
          foodPricing={foodDescriptions.currentRollPricing}
        />
        <Food
          foodImage={coconutRollImage}
          foodImageAlt="Photo of Coconut Rolls"
          foodName="Trini Coconut Rolls"
          foodOptions={foodDescriptions.coconutRollOptions}
          foodPricing={foodDescriptions.coconutRollPricing}
        />
        <Food
          foodImage={sweetbread}
          foodImageAlt="Photo of Sweetbread"
          foodName="Sweetbread"
          foodOptions={foodDescriptions.sweetBreadOptions}
          foodPricing={foodDescriptions.sweetBreadPricing}
        />
        <Food
          foodImage={greenSeasoningImage}
          foodImageAlt="Photo of Green Seasoning"
          foodName="Trini Green Seasoning"
          foodOptions={foodDescriptions.greenSeasoningOptions}
          foodPricing={foodDescriptions.greenSeasoningPricing}
        />
        <Food
          foodImage={fruitCake}
          foodImageAlt="Photo of Fruit Cake"
          foodName="Trini Fruit Cake"
          foodOptions={foodDescriptions.fruitCakeOptions}
          foodPricing={foodDescriptions.fruitCakePricing}
        />
        <Food
          foodImage={sorrel}
          foodImageAlt="Photo of Sorrel"
          foodName="Sorrel"
          foodOptions={foodDescriptions.sorrelOptions}
          foodPricing={foodDescriptions.sorrelPricing}
        />
        <Food
          foodImage={ponchDeCreme}
          foodImageAlt="Photo of Ponche De Creme"
          foodName="Ponche De Creme"
          foodOptions={foodDescriptions.poncheDeCremeOptions}
          foodPricing={foodDescriptions.poncheDeCremePricing}
        />
        <Food
          foodImage={pastel}
          foodImageAlt="Photo of Pastel"
          foodName="Pastel"
          foodOptions={foodDescriptions.pastelOptions}
          foodPricing={foodDescriptions.pastelPricing}
        />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
