const pelauOptions = `Choice of chicken or beef, Comes with coleslaw, Serving size 10-12 people`;
const pelauPricing = ["Chicken $150", "Beef $175"];

const cornSoupOptions = ``;
const cornSoupPricing = ["4-6 people $125", "8-10 people $160"];

const callalooOptions =
  "Choice of potato salad or macaroni salad or macaroni pie";
const callalooPricing = [
  "2-4 people $120",
  "4-6 people $160",
  "8-10 people $240",
];

const redBeansOptions = "Options: Red beans, black beans, pigeon peas";
const redBeansPricing = [
  "2-4 people $120",
  "4-6 people $190",
  "8-10 people $310",
];

const curryOptions = "Options: Beef, Chicken, Shrimp";
const curryPricing = [
  "Chicken:",
  "4-6 people $150",
  "8-10 people $220",
  "Beef: ",
  "4-6 people $170",
  "8-10 people $250",
  "Shrimp:",
  "4-6 people $170",
  "8-10 people $250",
];

const doublesOptions =
  "All condiments included mango chutney, chadon beni sauce, cucumber chutney";
const doublesPricing = ["24 doubles $160"];

const bakeAndFishOptions =
  "All condiments included lettuce, cucumbers, tomatoes, garlic sauce";
const bakeAndFishPricing = ["6 bakes $150"];

const alooPieOptions = "";
const alooPiePricing = ["1 dozen $125"];

const currentRollOptions = "";
const currentRollPricing = ["10-12 rolls $40"];

const coconutRollOptions = "";
const coconutRollPricing = ["10-12 rolls $40"];

const greenSeasoningOptions = "Available for shipping";
const greenSeasoningPricing = ["$30 per 16 oz bottle"];

const fruitCakeOptions = "A loa an of fruit cake";
const fruitCakePricing = ["8.5 inch x 4.56 inch for $45"];

const sorrelOptions = "";
const sorrelPricing = ["$30 per 24 oz bottle"];

const poncheDeCremeOptions = "";
const poncheDeCremePricing = ["$30 per 24 oz bottle"];

const pastelOptions = "12 Pastels";
const pastelPricing = ["Chicken:", "$100", "Beef or Pork:", "$120"];

const sweetBreadOptions = "";
const sweetBreadPricing = ["1 Sweetbread $35"];

const foodDescriptions = {
  pelauOptions,
  pelauPricing,
  cornSoupOptions,
  cornSoupPricing,
  callalooOptions,
  callalooPricing,
  redBeansOptions,
  redBeansPricing,
  curryOptions,
  curryPricing,
  doublesOptions,
  doublesPricing,
  bakeAndFishOptions,
  bakeAndFishPricing,
  alooPieOptions,
  alooPiePricing,
  currentRollOptions,
  currentRollPricing,
  coconutRollOptions,
  coconutRollPricing,
  greenSeasoningOptions,
  greenSeasoningPricing,
  fruitCakeOptions,
  fruitCakePricing,
  sorrelOptions,
  sorrelPricing,
  poncheDeCremeOptions,
  poncheDeCremePricing,
  pastelOptions,
  pastelPricing,
  sweetBreadOptions,
  sweetBreadPricing,
};

export default foodDescriptions;
