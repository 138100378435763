import "./Details.css";
import BackButton from "../images/back-button.png";
import { Link, useLocation } from "react-router-dom";

function Details() {
  const location = useLocation();
  const receivedData = location.state; // Access the passed data
  return (
    <div className="container">
      <img
        className="image"
        src={receivedData.foodImage}
        alt={receivedData.foodImage}
      />
      <div className="text-side">
        <Link className="back-button-div" to="/">
          <img className="back-button" src={BackButton} alt="Go Back" />
        </Link>
        <p className="name">{receivedData.foodName}</p>
        <div className="all-text">
          <p className="options">{receivedData.foodOptions}</p>
          {receivedData.foodPricing.map((item, index) => (
            <p className="pricing" key={index}>
              {item}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Details;
