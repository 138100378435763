import React from "react";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("xqkvaqjn");
  if (state.succeeded) {
    return (
      <p className="contact-succeed-message">Thank you for your purchase!</p>
    );
  }
  return (
    <div className="contact-section">
      <h3 className="contact-header">Order Here:</h3>
      <form onSubmit={handleSubmit} className="contact-flex">
        <label htmlFor="email"></label>
        <input
          className="email-input"
          id="email"
          type="email"
          name="email"
          placeholder="Your email"
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <label htmlFor="Order Details"></label>
        <textarea
          className="message-textarea"
          id="Order Details"
          name="Order Details"
          type="text"
          placeholder="Your name, phone number, and your order details"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <button className="button-1" type="submit" disabled={state.submitting}>
          Order
        </button>
      </form>
    </div>
  );
}
export default ContactForm;
