import React from "react";
import ContactForm from "./ContactForm.js"

function Footer() {
  return (
    <footer>
      <p className="footer-description">
        Small service catering available, delivery or pick up. Orders need to be
        placed at least 3 days in advance.
      </p>
      <ContactForm />
    </footer>
  );
}

export default Footer;
