import React from "react";
import { Link } from "react-router-dom";

function Food({ foodImage, foodImageAlt, foodName, foodOptions, foodPricing }) {
  let editedFoodName = "";
  for (let i = 0; i < foodName.length; i++) {
    if (foodName[i] !== " " && foodName[i] !== ",") {
      editedFoodName += foodName[i];
    }
  }

  const dataToPass = {
    foodImage: foodImage,
    foodName: foodName,
    foodOptions: foodOptions,
    foodPricing: foodPricing,
  };
  return (
    <div className="food-item">
      <img className="food-image" src={foodImage} alt={foodImageAlt} />
      <p className="food-name">{foodName}</p>
      <Link className="link" to={`/${editedFoodName}`} state={dataToPass}>
        <button className="button-2">See Details</button>
      </Link>
    </div>
  );
}

export default Food;
