import React from "react";
import triniFlagImage from "../images/triniFlagImage.png";

function Header() {
  return (
    <>
      <header className="header">
        <h1>Trini Cravings</h1>
        <img
          className="trini-flag"
          src={triniFlagImage}
          alt="Flag of Trinidad"
        />
      </header>
      <header className="header2">
        <h2>It's finally here in PDX. Trinidadian food at its best!</h2>
      </header>
    </>
  );
}

export default Header;
